export default function useUserState() {

  const userDataAuth = useCurrentUser()
  const userData = useState<FsbdUser>('userData')

  function setUserData(payload: FsbdUser) {
    userData.value = payload
  }

  function getUserData() {
    const facebookProvider = userDataAuth.value!.providerData.find(provider => provider.providerId === 'facebook.com')
    return {
      company: userData.value.refCompany!.title,
      responsible: `${userData.value.name}, ${userData.value.email}`,
      facebook: !!facebookProvider,
      facebookAccessToken: userData.value.facebookAccessToken
    }
  }

  const isSuperadmin = computed<boolean>(() => {
    return userData.value?.refRole.name === 'Superadmin'
  })
  const isAdmin = computed<boolean>(() => {
    return userData.value?.refRole.name === 'Admin'
  })
  const isResponsable = computed<boolean>(() => {
    return userData.value?.refRole.name === 'Responsable'
  })
  const isUsuario = computed<boolean>(() => {
    return userData.value?.refRole.name === 'Usuario'
  })

  return {
    userData,
    isSuperadmin: isSuperadmin.value,
    isAdmin: isAdmin.value,
    isResponsable: isResponsable.value,
    isUsuario: isUsuario.value,
    setUserData,
    getUserData
  }

}
